/**
 * 组织成员身份
 */
export const OrganizationMemberStatus = {

    /**
     * 管理员
     */
    MANAGER: 0,

    /**
     * 超级管理员
     */
    SUPER_MANAGER: 1,

    /**
     * 成员
     */
    MEMBER: 2,

}