<template>
  <div class="page layout-vertical">
    <div class="padding-horizontal-large padding-vertical font-size-large">
      <back-button @click="$router.back()"></back-button>
      <span class="margin-left">组织管理</span>
    </div>
    <div class="layout-flexible padding-horizontal-large padding-vertical" style="overflow: hidden"  v-if="organization">
      <ys-infinite-scroll-view style="height: 100%">
        <div class="layout-vertical layout-center">
          <div class="card padding padding-horizontal-large margin-top">
            <div class="font-weight-bold">组织信息</div>
            <div class="divider margin-vertical"></div>
            <div class="padding-vertical">
              <span>组织logo：</span>
              <ys-image-picker
                  class="image-avatar"
                  style="display: inline-block; vertical-align: middle"
                  :image.sync="organization.avatar"
                  @update:image="handleOrganizationAvatarInput"
              ></ys-image-picker>
            </div>
            <div class="padding-vertical">
              <span>组织名称：</span>
              <ys-click-to-edit
                  ref="inputOrganizationName"
                  single-click-to-edit
                  v-model="organization.name"
                  class="font-size-large"
                  @blur="handleOrganizationNameInput"
              ></ys-click-to-edit>
              <span class="fas fa-edit margin-left font-color-primary"
                    @click="$refs.inputOrganizationName.focus()"
              ></span>
            </div>
          </div>
          <div class="card padding padding-horizontal-large margin-top">
            <div class="layout-horizontal layout-middle">
              <span class="font-weight-bold">组织管理员</span>
              <div class="layout-flexible"></div>
              <div class="btn-edit-members font-color-primary"
                   @click="clickEditManagers">
                <span v-if="!isEditManagers">管理 <i class="fas fa-users-cog"></i></span>
                <span v-else>完成 <i class="fas fa-check"></i></span>
              </div>
            </div>
            <div class="divider margin-vertical"></div>
            <div class="font-color-secondary">超级管理员：</div>
            <div class="layout-horizontal layout-wrap" v-if="organizationSuperManagerList.length > 0 || isEditManagers">
              <organization-member
                  v-for="member in organizationSuperManagerList"
                  :key="member.id"
                  :member="member"
                  :is-edit="isEditManagers"
                  @delete="clickRemoveOrganizationManager(member)"
              ></organization-member>
              <organization-member
                  v-if="isEditManagers"
                  @click.native="clickAddSuperOrganizationManager"
              ></organization-member>
            </div>
            <div
                v-else
                class="font-size-large font-color-light-placeholder padding-vertical-large font-align-center"
            >
              无超级管理员
            </div>
            <div class="divider margin-vertical"></div>
            <div class="font-color-secondary">管理员：</div>
            <div class="layout-horizontal layout-wrap" v-if="organizationManagerList.length > 0 || isEditManagers">
              <organization-member
                  v-for="member in organizationManagerList"
                  :key="member.id"
                  :member="member"
                  :is-edit="isEditManagers"
                  @delete="clickRemoveOrganizationManager(member)"
              ></organization-member>
              <organization-member
                  v-if="isEditManagers"
                  @click.native="clickAddOrganizationManager"
              ></organization-member>
            </div>
            <div
                v-else
                class="font-size-large font-color-light-placeholder padding-vertical-large font-align-center"
            >
              无管理员
            </div>
          </div>
          <div class="card padding padding-horizontal-large margin-top">
            <div class="layout-horizontal layout-middle">
              <div class="font-weight-bold">组织成员</div>
              <div class="layout-flexible"></div>
              <div class="btn-edit-members font-color-primary"
                   @click="clickEditMembers">
                <span v-if="!isEditMembers">管理 <i class="fas fa-users-cog"></i></span>
                <span v-else>完成 <i class="fas fa-check"></i></span>
              </div>
            </div>
            <div class="divider margin-vertical"></div>
            <div
                v-if="organizationMemberList.length > 0 || isEditMembers"
                class="layout-horizontal layout-wrap"
            >
              <organization-member
                  v-for="member in organizationMemberList"
                  :member="member"
                  :is-edit="isEditMembers"
                  :key="member.id"
                  @delete="clickRemoveOrganizationMember(member)"
              ></organization-member>
              <organization-member
                  v-if="isEditMembers"
                  @click.native="clickAddOrganizationMember"
              >
              </organization-member>
            </div>
            <div v-else
                 class="font-size-large font-color-light-placeholder font-align-center padding-vertical-large"
            >
              无组织成员
            </div>

          </div>
        </div>
      </ys-infinite-scroll-view>
      <organization-member-picker-dialog
          :organization-id="organization.id"
          :visible.sync="managerPickerDialogVisible"
          @confirm="organizationManagerPicked"
      ></organization-member-picker-dialog>
      <organization-member-picker-dialog
          :organization-id="organization.id"
          :visible.sync="superManagerPickerDialogVisible"
          @confirm="superOrganizationManagerPicked"
      ></organization-member-picker-dialog>
      <account-picker-dialog
          :visible.sync="memberPickerDialogVisible"
          @confirm="organizationMemberPicked"
      ></account-picker-dialog>
      <delete-dialog
          :visible.sync="managerRemoveDialogVisible"
          title="从组织管理员中移除"
          message="您确定要从组织管理员中移除该成员吗？"
          confirm-button-text="移除"
          @confirm="confirmRemoveOrganizationManager"
      ></delete-dialog>
      <delete-dialog
          :visible.sync="memberRemoveDialogVisible"
          title="移除成员"
          message="您确定要从组织中移除该成员吗?"
          confirm-button-text="移除"
          @confirm="confirmRemoveOrganizationMember">
      </delete-dialog>
    </div>

  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import BackButton from "@/components/backbutton/BackButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import OrganizationMember from "@/pages/system/organization/OrganizationMember";
import {OrganizationMemberStatus} from "@/assets/javascript/organization-member";
import OrganizationMemberPickerDialog from "@/pages/system/dialog/OrganizationMemberPickerDialog";
import AccountPickerDialog from "@/pages/system/dialog/AccountPickerDialog";
import TextUtils from "@/assets/javascript/text-utils";
import AutosubmitHelper from "@/assets/javascript/autosubmit-helper";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import YsImagePicker from "@/components/wedigets/YsImagePicker";

const TAG_SET_MEMBER_STATUS = "set_member_status";

const TAG_ADD_MEMBER = "add_member";

const TAG_REMOVE_MEMBER = "remove_member";

export default {
  name: "Organization",
  components: {
    DeleteDialog,
    AccountPickerDialog,
    OrganizationMemberPickerDialog,
    OrganizationMember, YsClickToEdit, YsImagePicker, YsInfiniteScrollView, BackButton
  },
  mixins: [httpapi, AutosubmitHelper],
  data() {
    return {

      organization: null,

      /**
       * 组织成员列表
       */
      organizationMemberList: [],
      isEditMembers: false,

      /**
       * 组织管理员列表
       */
      organizationSuperManagerList: [],
      organizationManagerList: [],
      isEditManagers: false,

      /**
       * 组织成员弹窗
       */
      managerPickerDialogVisible: false,
      superManagerPickerDialogVisible: false,
      memberPickerDialogVisible: false,

      /**
       * 从组织管理员中移除
       */
      managerRemoveDialogVisible: false,
      removingOrganizationManager: null,

      /**
       * 从组织成员中移除
       */
      memberRemoveDialogVisible: false,
      removingOrganizationMember: null,
    }
  },
  activated() {
    this.loadingCode++;
    this.organization = null;
    this.organizationSuperManagerList = [];
    this.organizationManagerList = [];
    this.organizationMemberList = [];
    this.isEditManagers = false;
    this.isEditMembers = false;
    this.managerRemoveDialogVisible = false;
    this.memberRemoveDialogVisible = false;
    this.loadOrganization();
  },
  methods: {
    handleOrganizationNameInput() {
      this.createSetOrganizationTask();
    },
    handleOrganizationAvatarInput() {
      this.createSetOrganizationTask();
    },
    clickEditMembers() {
      this.isEditMembers = !this.isEditMembers;
    },
    clickEditManagers() {
      this.isEditManagers = !this.isEditManagers;
    },
    clickAddOrganizationManager() {
      this.managerPickerDialogVisible = true;
    },
    clickAddSuperOrganizationManager() {
      this.superManagerPickerDialogVisible = true;
    },
    clickAddOrganizationMember() {
      this.memberPickerDialogVisible = true;
    },
    clickRemoveOrganizationManager(member) {
      this.removingOrganizationManager = member;
      this.managerRemoveDialogVisible = true;
    },
    confirmRemoveOrganizationManager() {
      this.createTask({
        tag: TAG_SET_MEMBER_STATUS,
        params: {
          memberId: this.removingOrganizationManager.id,
          status: OrganizationMemberStatus.MEMBER,
        },
        runFunc: this.setOrganizationMemberStatus,
      });
    },
    clickRemoveOrganizationMember(member) {
      this.removingOrganizationMember = member;
      this.memberRemoveDialogVisible = true;
    },
    confirmRemoveOrganizationMember() {
      this.createTask({
        tag: TAG_REMOVE_MEMBER,
        params: {
          memberId: this.removingOrganizationMember.id,
        },
        runFunc: this.removeOrganizationMember,
      })
    },
    organizationManagerPicked(memberList) {
      let member = memberList[0];
      if (member.status === OrganizationMemberStatus.MANAGER
          || member.status === OrganizationMemberStatus.SUPER_MANAGER
      ) {
        this.$message.warning('Ta已经是该组织的管理员');
        return;
      }
      this.createTask({
        tag: TAG_SET_MEMBER_STATUS,
        params: {
          memberId: member.id,
          status: OrganizationMemberStatus.MANAGER,
        },
        runFunc: this.setOrganizationMemberStatus,
      });
    },
    superOrganizationManagerPicked(memberList) {
      let member = memberList[0];
      if (member.status === OrganizationMemberStatus.SUPER_MANAGER) {
        this.$message.warning('Ta已经是该组织的超级管理员');
      }
      this.createTask({
        tag: TAG_SET_MEMBER_STATUS,
        params: {
          memberId: member.id,
          status: OrganizationMemberStatus.SUPER_MANAGER,
        },
        runFunc: this.setOrganizationMemberStatus,
      })
    },
    organizationMemberPicked(accountList) {
      let account = accountList[0];
      this.createTask({
        tag: TAG_ADD_MEMBER,
        params: {
          organizationId: this.organization.id,
          accountId: account.id,
        },
        runFunc: this.addOrganizationMember,
      });
    },
    createSetOrganizationTask() {
      this.createTask({
        tag: `set_organization_` + this.organization.id,
        params: this.organization,
        runFunc: this.setOrganization,
      });
    },
    loadOrganization() {
      this.$reqGet({
        path: `/organization/with_private_info/${this.$route.query.id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.organization = res.data;
            this.loadOrganizationMembers();
          })
          .catch(() => this.$message.error('加载失败'));
    },
    loadOrganizationMembers() {
      this.$reqGet({
        path: `/organization/member/list/${this.$route.query.id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.organizationMemberList = res.data;
            let managerList = [];
            let superManagerList = [];
            this.organizationMemberList.forEach(member => {
              switch (member.status) {
                case OrganizationMemberStatus.MANAGER:
                  managerList.push(member);
                  break;
                case OrganizationMemberStatus.SUPER_MANAGER:
                  managerList.push(member);
                  superManagerList.push(member);
                  break;
              }
            });
            this.organizationManagerList = managerList;
            this.organizationSuperManagerList = superManagerList;
          })
          .catch(() => this.$message.error('加载失败'));
    },
    async setOrganization(organization) {
      if (TextUtils.isBlank(organization.name)) return;
      if (!organization.avatar) return;
      let isOrganizationNameExists = false;
      await this.$reqGet({
        path: `/organization/exists/organization_name/${encodeURIComponent(organization.name)}/ignore_id/${organization.id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            isOrganizationNameExists = res.data;
          })
          .promise();

      if (isOrganizationNameExists) {
        this.$message.error('组织名称已被占用');
        return;
      }

      await this.$reqPut({
        path: `/organization/${organization.id}/${encodeURIComponent(organization.name)}/${organization.avatar.id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            window.eventBus.$emit('update:organization', {id: organization.id});
          })
          .catch(() => this.$message('保存失败'))
          .promise();
    },
    async setOrganizationMemberStatus({memberId, status}) {
      await this.$reqPut({
        path: `/organization/member/${memberId}/status/${status}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.loadOrganizationMembers();
          })
          .catch(() => this.$message.error('设置失败'))
          .promise();
    },
    async addOrganizationMember({organizationId, accountId}) {
      await this.$reqPost({
        path: `/organization/member/${organizationId}/${accountId}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.loadOrganizationMembers();
          })
          .catch(() => this.$message.error('设置失败'))
          .promise();
    },
    async removeOrganizationMember({memberId}) {
      await this.$reqDelete({
        path: `/organization/member/${memberId}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.loadOrganizationMembers();
          })
          .catch(() => this.$message.error('移除失败'))
          .promise();
    }
  }
}
</script>

<style scoped>

.page {
  height: 100vh;
}

.card {
  width: 600px;
}

.btn-edit-members {
  user-select: none;
  cursor: pointer;
}

.btn-edit-members:hover {
  opacity: .9;
}

.image-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
</style>