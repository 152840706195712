<template>
  <ys-dialog
      :visible.sync="innerVisible"
      @update:visible="updateVisible"
      :title="title"
  >
    <ys-infinite-scroll-view style="height: 50vh;">
      <div class="layout-horizontal layout-wrap">
        <organization-member
            v-for="member in organizationMemberList"
            :key="member.id"
            :member="member"
            @click.native="clickOrganizationMember(member)"
        ></organization-member>
      </div>
    </ys-infinite-scroll-view>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="clickCancel">取消选择</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import OrganizationMember from "@/pages/system/organization/OrganizationMember";
import YsButton from "@/components/wedigets/YsButton";

export default {
  name: "OrganizationMemberPickerDialog",
  mixins: [httpapi],
  components: {YsButton, OrganizationMember, YsInfiniteScrollView, YsDialog},
  props: {
    visible: Boolean,
    organizationId: Number,
    title: {
      type: String,
      default: '选择组织成员',
    }
  },
  data() {
    return {

      /**
       * 组织成员管理
       */
      organizationMemberList: [],

      /**
       * 显示弹窗
       */
      innerVisible: false,
    }
  },
  watch: {
    visible: {
      handler(visible) {
        this.innerVisible = visible;
        if (this.innerVisible) {
          this.loadOrganizationMembers();
        }
      },
      immediate: true,
    }
  },
  methods: {
    clickCancel() {
      this.innerVisible = false;
      this.updateVisible();
    },
    clickOrganizationMember(member) {
      this.innerVisible = false;
      this.updateVisible();
      this.$emit('confirm', [member]);
    },
    updateVisible() {
      this.$emit('update:visible', this.innerVisible);
    },
    loadOrganizationMembers() {
      this.$reqGet({
        path: `/organization/member/list/${this.organizationId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.organizationMemberList = res.data;
          })
          .catch(() => this.$message.error('加载失败'))
    },

  }
}
</script>

<style scoped>

</style>