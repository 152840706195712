<template>
  <div
      class="organization-member layout-vertical layout-middle layout-center padding padding-large"
      :class="{
        'is-empty' : !member
      }"
  >
    <template v-if="member">
      <user-avatar
          :user="member.account"
      ></user-avatar>
      <div class="btn-delete-container"
           v-if="isEdit"
           @click="clickDelete"
      >
        <div class="btn-delete font-color-danger">
          <i class="fas fa-minus-circle"></i>
        </div>
      </div>
      <div class="margin-top">
        <span v-if="member.account.name">{{ member.account.name }}</span>
        <span v-else>{{member.account.account}}</span>
      </div>
      <div v-if="member.dietitian" class="font-color-danger font-size-extra-small">营养师</div>
      <div v-else style="color: transparent;">普通</div>
    </template>
    <template v-else>
      <div class="font-size-extra-large">+</div>
      <div class="margin-top">添加</div>
    </template>
  </div>
</template>

<script>
import UserAvatar from "@/components/user/UserAvatar";

export default {
  name: "OrganizationMember",
  components: {UserAvatar},
  props: {
    member: Object,
    isEdit: Boolean,
  },
  methods: {
    clickDelete() {
      this.$emit('delete', this.member);
    }
  }
}
</script>

<style scoped>

.organization-member {
  border-radius: 8px;
  cursor: pointer;
  width: 56px;
  margin: 5px;
}

.organization-member.is-empty {
  border: 1px dashed #dadada;
}

.organization-member:hover {
  background-color: #f1f1f1;
}

.btn-delete-container {
  height: 0px;
  position: relative;
  top: -42px;
  left: 10px;
}

.btn-delete {
  background-color: white;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  font-size: 12pt;
  box-sizing: border-box;
  padding: 1px;
}

.btn-delete:hover {
  box-shadow: 0 0 4px #ff5500;
}

</style>