<template>
  <ys-dialog
      :visible.sync="innerVisible"
      @update:visible="updateVisible"
      :title="title"
  >
    <div slot="title"
         class="layout-horizontal layout-middle padding-horizontal-large padding-vertical"
    >
      <div class="font-weight-bold">{{ title }}</div>
      <div class="layout-flexible"></div>
      <ys-input
          class="input-search"
          placeholder="搜索账号"
          :maxlength="50"
          v-model="search"
      ></ys-input>
    </div>
    <ys-infinite-scroll-view style="height: 50vh">
      <div class="layout-horizontal layout-wrap">
        <div class="account layout-vertical layout-center padding padding-large margin margin-small"
             v-for="account in accountList"
             :key="account.id"
             @click="clickAccount(account)"
        >
          <user-avatar
              :user="account"
          ></user-avatar>
          <div class="margin-top">
            <span v-if="account.name">{{ account.name }}</span>
            <span v-else>{{account.account}}</span>
          </div>
          <div class="font-color-danger" v-if="account.dietitian">营养师</div>
        </div>
      </div>
    </ys-infinite-scroll-view>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsInput from "@/components/wedigets/YsInput";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import UserAvatar from "@/components/user/UserAvatar";

export default {
  name: "AccountPickerDialog",
  mixins: [httpapi],
  components: {UserAvatar, YsInfiniteScrollView, YsInput, YsDialog},
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '选择账号',
    }
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 账号列表
       */
      accountList: [],
      pageNum: 1,
      totalPages: 0,
      pageSize: 20,

      /**
       * 搜索条件
       */
      search: null,
      searchTimeoutId: null,

      /**
       * 显示弹窗
       */
      innerVisible: false,
    }
  },
  watch: {
    visible: {
      handler(visible) {
        this.loadingCode++;

        this.innerVisible = visible;
        this.accountList = [];
        this.pageNum = 1;
        this.totalPages = 0;
        this.search = null;
        this.startSearch();
      },
      immediate: true,
    },
    search: {
      handler() {
        this.loadingCode++;

        this.startSearch();
      },
      immediate: true,
    }
  },
  methods: {
    clickCancel() {
      this.innerVisible = false;
      this.updateVisible();
    },
    clickAccount(account) {
      this.innerVisible = false;
      this.updateVisible();
      this.$emit('confirm', [account]);
    },
    startSearch() {
      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
      }

      this.searchTimeoutId = setTimeout(() => {
        this.loadAccountList();
        this.searchTimeoutId = null;
      });
    },
    updateVisible() {
      this.$emit('update:visible', this.innerVisible);
    },
    enterBusy() {
      if (this.busyLoadingCode === this.loadingCode) return false;
      this.busyLoadingCode = this.loadingCode;
      return true;
    },
    exitBusy() {
      this.busyLoadingCode = 0;
      return true;
    },
    loadAccountList(pageNum = 1) {
      if (!this.enterBusy()) return;
      this.$reqGet({
        path: `/account/list/${pageNum}/${this.pageSize}` + (this.search ? `/${encodeURIComponent(this.search)}` : '')
      })
          .returnWhenCodeMatches()
          .then(res => {
            let accountList = pageNum === 1 ? [] : this.accountList;
            this.$appendAfter(accountList, res.data.list);
            this.pageNum = pageNum;
            this.totalPages = res.data.pages;
            this.accountList = accountList;
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.exitBusy());
    },
    loadMore() {
      if (this.pageNum < this.totalPages) this.loadAccountList(this.pageNum + 1);
    }
  }
}
</script>

<style scoped>

.account {
  width: 64px;
  border-radius: 8px;
  cursor: pointer;
}

.account:hover {
  background-color: #f1f1f1;
}

</style>